import { BaseConfig, ClientVersion } from '@virtual-trials-workspace/models';

class LocalConfig extends BaseConfig {
  name = 'stage';
  appVersion = require('../../../../package.json').version + '-vt-staging';
  baseApiEndpoint = 'https://api.virtualtrials.staging.ert.com';
  baseWebSocketEndpoint = 'wss://wsapi.virtualtrials.staging.ert.com';
  production = false;
  angularEnableProdMode = false;
  deviceStreamGatewayAuthToken =
    'AgEUYW5ib3gtc3RyZWFtLWdhdGV3YXkCBGRlbW8AAhQyMDIxLTAzLTEyVDE2OjE1OjAzWgAABiBJK4wMPJszstTSgqw6Wu1R9vRwYiWshPhn9QPLGEs9tA';
  deviceStreamGatewayUrl = 'https://stream-gateway.device.staging.ert.com';
  websocketKeepAliveTimerMinutes = 4;
  emulationHealthCheckIntervalMinutes = 1;
  translationsBaseApiEndpoint =
    'https://api.virtualtrials.staging.ert.com/translations';
  visitCodeAPIEndpoint = 'https://virtualtrials.staging.ert.com/standalone/visit';
  translationsApiKey = '07xwcJeZhC9aCRIQbY1liDOB9DvH38x2h8IRIhuh';
  GATrakingCode = 'G-VSKSQDM9PC';
  airbrakeProjectKey = '59ea57ee008a33eb4676315271241773';
  supportedClients = {
    min: {
      chrome: new ClientVersion(92, 0, 0),
      safari: new ClientVersion(14, 0, 0),
    },
  };
  supportedViewPort = { minWidth: 1024, minHeight: 768 };
  platformUpdateUrls = {
    ios: 'https://apps.apple.com/app/apple-store/id535886823',
    android: 'https://play.google.com/store/apps/details?id=com.android.chrome',
    default: 'https://www.google.com/chrome',
  };
  session = { timeoutWarningTime: 60000 };
  auth = {
    redirectUrl: 'https://virtualtrials.staging.ert.com',
    loginUrl: 'https://gsso.val1.ert.com/service/api/v2/oauth2/authorize',
    clientId: 'virtualvisits',
    logoutUrl:
      'https://gsso.val1.ert.com/gsso/services/api/v2/sessions/logout',
    checkSessionUrl:
      'https://gsso.val1.ert.com/gsso/services/api/v1/sessions/check',
    rolesUrl: 'https://gsso.val1.ert.com/gsso/services/api/v2/users/roles',
  };
  navigatorHeaderScriptId = 'ert-global-header-script';
  navigatorHeaderScriptUrl = `https://epf-header.val1.ert.com/header.js`;
}

export const environment = new LocalConfig();
